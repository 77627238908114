import React from "react"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from "../../components/layout"
import ContactForm, { PrettyWaitingListForm } from "../../components/contact-form"
import ThumbnailImg from "../../components/thumbnail-img"
import rehypeReact from "rehype-react"

export default function Page() {
    return (
        <Layout>
          <Helmet>
            <meta name='robots' content='noindex, follow'/>
          </Helmet>
          {/* CONTENT START */}
<div id="outline-container-orgc6c8ff7" class="outline-2">
<h2 id="site-inspection-report">Site Inspection Report</h2>
<div class="outline-text-2" id="text-site-inspection-report">
<table border="2" cellspacing="0" cellpadding="6" rules="groups" frame="hsides">


<colgroup>
<col  class="org-left" />

<col  class="org-left" />
</colgroup>
<tbody>
<tr>
<td class="org-left"><b>Report Commissioned by</b></td>
<td class="org-left">Matt Meintjes</td>
</tr>

<tr>
<td class="org-left"><b>Property Address</b></td>
<td class="org-left">Manuka Cove, 216 Manuka Road, Glenfield</td>
</tr>

<tr>
<td class="org-left"><b>Report Scope</b></td>
<td class="org-left">To inspect and report on the condition of the roofs on the above property with recommendations as to repairs, maintenance and estimated lifespan to replacement</td>
</tr>

<tr>
<td class="org-left"><b>Roof Type</b></td>
<td class="org-left">Corrugated coloursteel, Estimated age approximately 15 years</td>
</tr>

<tr>
<td class="org-left"><b>Inspection carried out by</b></td>
<td class="org-left">Des Cowperthwaite on 10<sup>th</sup> October 2018</td>
</tr>
</tbody>
</table>
</div>

<div id="outline-container-org9e4dbf2" class="outline-3">
<h3 id="org9e4dbf2">Summaries</h3>
<div class="outline-text-3" id="text-org9e4dbf2">
<ul class="org-ul">
<li><a href="#h-25c5j02008i0">Summary of recommendations for general condition of roofs units 1-39</a></li>
<li><a href="#h-8hz5j02008i0">Wall cladding all units</a></li>
<li><a href="#h-stw5j02008i0">Conclusions &amp; recommendations for gym building</a></li>
<li><a href="#h-l748j02008i0">Summary (all roofs)</a></li>
</ul>
</div>
</div>
<div id="outline-container-org326cf79" class="outline-3">
<h3 id="h-vpz3j02008i0">Table of contents</h3>
<div class="outline-text-3" id="text-h-vpz3j02008i0">
<ul class="org-ul">
<li><a href="#h-z564j02008i0">Introduction</a></li>
<li><a href="#h-dca4j02008i0">Findings</a>
<ul class="org-ul">
<li><a href="#h-bmc4j02008i0">Generic Condition of Roofs Units 1-39</a>
<ul class="org-ul">
<li><a href="#h-7te4j02008i0">Roof type</a></li>
<li><a href="#h-x7h4j02008i0">Condition</a>
<ul class="org-ul">
<li><a href="#h-cej4j02008i0">Roofs</a></li>
<li><a href="#h-e3o4j02008i0">Flashings</a></li>
<li><a href="#h-xcr4j02008i0">Roof Penetrations</a></li>
<li><a href="#h-wsu4j02008i0">Gutters</a></li>
</ul></li>
<li><a href="#h-8bx4j02008i0">Conclusions &amp; Recommendations</a>
<ul class="org-ul">
<li><a href="#h-jxz4j02008i0">Roofs</a></li>
<li><a href="#h-mq35j02008i0">Flashings</a></li>
<li><a href="#h-cp65j02008i0">Roof Penetrations</a></li>
<li><a href="#h-ro95j02008i0">Gutters</a></li>
</ul></li>
<li><a href="#h-25c5j02008i0">Summary of Recommendations</a>
<ul class="org-ul">
<li><a href="#h-qfe5j02008i0">Roofs</a></li>
<li><a href="#h-cih5j02008i0">Flashings</a></li>
<li><a href="#h-mfk5j02008i0">Roof penetrations</a></li>
<li><a href="#h-tsm5j02008i0">Gutters</a></li>
<li><a href="#h-n1p5j02008i0">Maintenance plan</a></li>
</ul></li>
</ul></li>
<li><a href="#h-pfr5j02008i0">Recreational Building</a>
<ul class="org-ul">
<li><a href="#h-cht5j02008i0">Findings</a></li>
<li><a href="#h-stw5j02008i0">Conclusions &amp; Recommendations</a></li>
</ul></li>
<li><a href="#h-8hz5j02008i0">Wall cladding all Units</a></li>
</ul></li>
<li><a href="#h-8r16j02008i0">Individual Units</a>
<ul class="org-ul">
<li><a href="#h-fk46j02008i0">Block 1 (Units 1-10)</a>
<ul class="org-ul">
<li><a href="#h-xa76j02008i0">Unit 1</a></li>
<li><a href="#h-0t96j02008i0">Units 2, 3</a></li>
<li><a href="#h-0zb6j02008i0">Units 4, 5</a></li>
<li><a href="#h-evd6j02008i0">Units 6, 7</a></li>
<li><a href="#h-e3h6j02008i0">Unit 8</a></li>
<li><a href="#h-w7k6j02008i0">Units 9, 10</a></li>
</ul></li>
<li><a href="#h-djm6j02008i0">Block 2 (Units 11-18)</a>
<ul class="org-ul">
<li><a href="#h-8eo6j02008i0">Units 11, 12</a></li>
<li><a href="#h-5aq6j02008i0">Units 13, 14</a></li>
<li><a href="#h-0as6j02008i0">Units 15, 16</a></li>
<li><a href="#h-bdu6j02008i0">Units 17, 18</a></li>
</ul></li>
<li><a href="#h-ikw6j02008i0">Block 3 (Units 19-26)</a>
<ul class="org-ul">
<li><a href="#h-nky6j02008i0">Unit 19</a></li>
<li><a href="#h-u917j02008i0">Units 20, 21</a></li>
<li><a href="#h-ur37j02008i0">Units 22, 23</a></li>
<li><a href="#h-3h67j02008i0">Units 24, 25</a></li>
<li><a href="#h-xz87j02008i0">Unit 26</a></li>
</ul></li>
<li><ul class="org-ul">
<li><a href="#h-zte7j02008i0">Units 27, 28</a></li>
<li><a href="#h-pqh7j02008i0">Unit 29</a></li>
</ul></li>
<li><a href="#h-37l7j02008i0">Block 5 (Units 30-35)</a>
<ul class="org-ul">
<li><a href="#h-zcn7j02008i0">Units 30 31</a></li>
<li><a href="#h-nfp7j02008i0">Units 32, 33</a></li>
<li><a href="#h-6or7j02008i0">Units 34, 35</a></li>
</ul></li>
<li><a href="#h-8ft7j02008i0">Block 6 (Units 36-39)</a>
<ul class="org-ul">
<li><a href="#h-wew7j02008i0">Unit 36</a></li>
<li><a href="#h-c5z7j02008i0">Units 37, 38</a></li>
<li><a href="#h-9x18j02008i0">Unit 39</a></li>
</ul></li>
</ul></li>
<li><a href="#h-l748j02008i0">Summary (all Roofs)</a></li>
</ul>
</div>
</div>
<div id="outline-container-org6652e8c" class="outline-3">
<h3 id="h-z564j02008i0">Introduction</h3>
<div class="outline-text-3" id="text-h-z564j02008i0">
<ul class="org-ul">
<li>A non invasive inspection was carried out from on the main Unit roofs in fine, dry conditions.</li>
<li>Lower lean to roofs were viewed and assessed from the upper roofs.</li>
<li>Garage roofs and the underside of roofing forming eaves were viewed from a cherry picker.</li>
<li>For the purpose of identification the roofs are numbered 1 to 39 as per the Unit numbers on the included Google roof plan.</li>
<li>As the roofs are of a similar age and condition, the report firstly covers the generic condition applicable to all Unit roofs, which includes the lower lean to and garage roofs.</li>
<li>The condition of the Recreational Building roof is covered separately.</li>
<li>Brief comments on the condition of individual Unit roofs are also included as necessary. For the purpose of commenting, the Units have been listed in groups noted as Blocks 1-6 on the Google roof plan.</li>
<li>A list of historic and current leaks as reported by Unit owners/occupiers was provided for assessing possible causes during the inspection.</li>
<li>A separate file of photographs accompanies the report illustrating the points noted under Findings.</li>
<li>A second, separate file of photographs gives an overview of the condition of individual Units for the benefit of owners.</li>
</ul>
</div>
</div>
<div id="outline-container-org7b90148" class="outline-3">
<h3 id="h-dca4j02008i0">Findings</h3>
<div class="outline-text-3" id="text-h-dca4j02008i0">
</div>
<div id="outline-container-orga92bdf4" class="outline-4">
<h4 id="h-bmc4j02008i0">Generic Condition of Roofs Units 1-39</h4>
<div class="outline-text-4" id="text-h-bmc4j02008i0">
</div>
<div id="outline-container-orge73e5f2" class="outline-5">
<h5 id="h-7te4j02008i0">Roof type</h5>
<div class="outline-text-5" id="text-h-7te4j02008i0">
<ul class="org-ul">
<li>0.4mm gauge Corrugated coloursteel fixed with Tek type screws.</li>
<li>Estimated age 15 years.</li>
<li>Main roof areas to all Units are single plane, low pitch roofs of
approximately 5 degrees.</li>
<li>Garage roofs are a mixture of single plane, gabled and drape curved,
low pitch roofs.</li>
</ul>
</div>
</div>
<div id="outline-container-org0942605" class="outline-5">
<h5 id="h-x7h4j02008i0">Condition</h5>
<div class="outline-text-5" id="text-h-x7h4j02008i0">
</div>
<div id="outline-container-org5ac7a61" class="outline-6">
<h6 id="h-cej4j02008i0">Roofs</h6>
<div class="outline-text-6" id="text-h-cej4j02008i0">
<ul class="org-ul">
<li><p>
Overall, the corrugated coloursteel still appears to be in sound
condition.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/01-087-IMG_4232.JPG" label="photo 1" />, <ThumbnailImg src="/images/roof-photos/general/02-023-IMG_4052.JPG" label="photo 2" />, <ThumbnailImg src="/images/roof-photos/general/03-006-IMG_4025.JPG" label="photo 3" />
</p></li>
<li><p>
The paint surface is oxidizing but still appears secure with no
obvious signs of flaking or surface corrosion.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/04-097-IMG_4277.JPG" label="photo 4" />
</p></li>
<li><p>
The cleanliness of the surface varies slightly on different blocks of
units. There is generally a film of dirt/black algae in the troughs of the corrugations and there are a relatively sparsely spread lichen growths on some roof planes.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/05-030-IMG_4088.JPG" label="photo 5" />, <ThumbnailImg src="/images/roof-photos/general/06-017-IMG_4010.JPG" label="photo 6" />
</p></li>
<li>In the areas which could be safely viewed, there appears to be no
obvious signs of underside or surface corrosion where the roofing overhangs into the gutters.</li>
<li>In a non invasive inspection the roofing sitting under the cover of
flashings could not be readily viewed. There is an accumulation of dirt/debris under the flashings.</li>
<li><p>
The heads of a number of the Tek type screws spread randomly over the
roofs are rusting. They are more prevalent on some blocks of Units than others.
</p>

<p>
On a relatively small number the rust is advanced, while the early stages of corrosion is visible on a percentage of others.
</p>

<p>
The percentage of rusting fixings appears to be relatively small compared with the overall number in the roofs. (An overall approximate estimate only would be around 15%)
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/07-076-IMG_4194.JPG" label="photo 7" />, <ThumbnailImg src="/images/roof-photos/general/08-028-IMG_4067.JPG" label="photo 8" />, <ThumbnailImg src="/images/roof-photos/general/09-026-IMG_4058.JPG" label="photo 9" />, <ThumbnailImg src="/images/roof-photos/general/10-03-SAM_0608.JPG" label="photo 10" />
</p></li>

<li><p>
Some of the screws have been over tensioned at the time of
installation slightly indenting the crest of the corrugations. A few are angled, affecting the seal of the washers and have been overlaid with sealants.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/11-025-IMG_4054.JPG" label="photo 11" />, <ThumbnailImg src="/images/roof-photos/general/12-086-IMG_4231.JPG" label="photo 12" />
</p></li>
<li>Over tensioning has also damaged a number of the neoprene washers.
<ThumbnailImg src="/images/roof-photos/general/13-022-IMG_4044.JPG" label="photo 13" />, <ThumbnailImg src="/images/roof-photos/general/14-015-IMG_3997.JPG" label="photo 14" /></li>
<li><p>
On many of the roof planes the heads of fixings have been overlaid
with sealants.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/15-007-IMG_4130.JPG" label="photo 15" />, <ThumbnailImg src="/images/roof-photos/general/16-094-IMG_4264.JPG" label="photo 16" />, <ThumbnailImg src="/images/roof-photos/general/17-009-IMG_4132.JPG" label="photo 17" />, <ThumbnailImg src="/images/roof-photos/general/18-02-SAM_0609.JPG" label="photo 18" />
</p></li>
<li>The troughs of the corrugations have not been turned down along the
gutter lines.</li>
<li><p>
On several roof planes there have been short lengths of corrugated
coloursteel used to patch the existing roofing.
</p>

<p>
The laps appear to have been sealed. There are a few spots showing edge creep corrosion and there is rust on a corrugation on one of the inserts where it laps.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/19-039-IMG_3991.JPG" label="photo 19" />, <ThumbnailImg src="/images/roof-photos/general/20-080-IMG_4208.JPG" label="photo 20" />, <ThumbnailImg src="/images/roof-photos/general/21-081-IMG_4209.JPG" label="photo 21" />, <ThumbnailImg src="/images/roof-photos/general/22-069-IMG_4162.JPG" label="photo 22" />, <ThumbnailImg src="/images/roof-photos/general/23-084-IMG_4219.JPG" label="photo 23" />, <ThumbnailImg src="/images/roof-photos/general/24-085-IMG_4220.JPG" label="photo 24" />
</p>

<p>
The inserts on Unit 38 in particular appear to be used sheets with poorly fitting laps, patched holes and an underlap acting as an overlap down one side.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/25-01-SAM_0613.JPG" label="photo 25" />, <ThumbnailImg src="/images/roof-photos/general/26-107-IMG_4400.JPG" label="photo 26" />, <ThumbnailImg src="/images/roof-photos/general/27-05-IMG_4401.JPG" label="photo 27" />
</p>

<p>
A small square of Flashband or similar bituminous foil tape adjacent to the above appears to have been left on the roof rather than sealing any holes and needs to be checked.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/28-06-IMG_4399.JPG" label="photo 28" />
</p></li>

<li><p>
The exposed underside of the roofing where it forms eaves appears to
be in sound condition, with no obvious signs of corrosion.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/29-103-IMG_4354.JPG" label="photo 29" />, <ThumbnailImg src="/images/roof-photos/general/30-029-IMG_4074.JPG" label="photo 30" />
</p></li>
<li><p>
The underside of the barge flashings and gutters also appear to be in
clean, sound condition, although there is a very small strip on the edge of a barge flashing showing the early signs of corrosion.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/31-07-IMG_4355.JPG" label="photo 31" />
</p></li>
<li>In a non invasive inspection it was not possible to view the roofing
underlays. Being under cover should still be in sound condition.</li>
</ul>
</div>
</div>
<div id="outline-container-orge7e6641" class="outline-6">
<h6 id="h-e3o4j02008i0">Flashings</h6>
<div class="outline-text-6" id="text-h-e3o4j02008i0">
<ul class="org-ul">
<li><p>
Overall the barge and apron flashings still appear to be in sound
condition.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/32-109-SAM_0583.JPG" label="photo 32" />, <ThumbnailImg src="/images/roof-photos/general/33-16-IMG_4352.JPG" label="photo 33" />, <ThumbnailImg src="/images/roof-photos/general/34-052-IMG_4051.JPG" label="photo 34" />, <ThumbnailImg src="/images/roof-photos/general/35-047-IMG_4020.JPG" label="photo 35" />, <ThumbnailImg src="/images/roof-photos/general/36-046-IMG_4018.JPG" label="photo 36" />
</p></li>
<li><p>
There is a build up of dirt/leaf matter under the barge flashings. In
some areas there is also a build up against the flashings.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/37-058-IMG_4093.JPG" label="photo 37" />, <ThumbnailImg src="/images/roof-photos/general/38-066-IMG_4144.JPG" label="photo 38" />
</p></li>
<li><p>
The top end of the short lengths of side barge flashings in the
situations where one roof plane extends beyond another, have not been correctly detailed. The ends are variously either open or have a closure piece formed with sealant.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/39-104-IMG_4377.JPG" label="photo 39" />, <ThumbnailImg src="/images/roof-photos/general/40-090-IMG_4252.JPG" label="photo 40" />, <ThumbnailImg src="/images/roof-photos/general/41-099-IMG_4294.JPG" label="photo 41" />, <ThumbnailImg src="/images/roof-photos/general/42-098-IMG_4283.JPG" label="photo 42" />
</p></li>
<li><p>
The heads of many of the Tek type screw securing the barge flashings
are rusting, with the rust being advanced in many instances.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/43-049-IMG_4033.JPG" label="photo 43" />, <ThumbnailImg src="/images/roof-photos/general/44-042-IMG_4001.JPG" label="photo 44" />, <ThumbnailImg src="/images/roof-photos/general/45-089-IMG_4236.JPG" label="photo 45" />
</p></li>
<li><p>
There are a few examples where the fixings have been angled with
washers not seating correctly or with heads of fixings sitting proud of the surface.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/46-065-IMG_4141.JPG" label="photo 46" />, <ThumbnailImg src="/images/roof-photos/general/47-043-IMG_4005.JPG" label="photo 47" />, <ThumbnailImg src="/images/roof-photos/general/48-110-SAM_0612.JPG" label="photo 48" />
</p></li>
<li><p>
Many fixings have been overlaid with sealants.
</p>

<p>
Sealant has also been placed over laps and rivets securing flashing laps.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/49-038-IMG_3990.JPG" label="photo 49" />, <ThumbnailImg src="/images/roof-photos/general/50-078-IMG_4197.JPG" label="photo 50" />
</p></li>

<li><p>
The edge of one of the side aprons has been sealed to the roof along
its full length.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/51-072-IMG_4185.JPG" label="photo 51" />, <ThumbnailImg src="/images/roof-photos/general/52-074-IMG_4188.JPG" label="photo 52" />
</p></li>
<li><p>
Horizontal corrugated cladding where Units step up or down from each
other appears to be in sound condition. Some fixings have been overlaid with sealant, which appears to have been a precautionary measure.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/53-15-IMG_3988.JPG" label="photo 53" />, <ThumbnailImg src="/images/roof-photos/general/54-11-IMG_4120.JPG" label="photo 54" />
</p></li>
</ul>
</div>
</div>
<div id="outline-container-org556a2c3" class="outline-6">
<h6 id="h-xcr4j02008i0">Roof Penetrations</h6>
<div class="outline-text-6" id="text-h-xcr4j02008i0">
<ul class="org-ul">
<li><p>
The smaller circular vent pipes are flashed to the roof with Dektite
type rubber boot flashings. Many of the flashings have been overlaid with sealants and in some instances the corrugations on the high side have been filled with sealant to try and form a flatter area to assist shedding water around the pipes.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/55-036-IMG_3985.JPG" label="photo 55" />, <ThumbnailImg src="/images/roof-photos/general/56-053-IMG_4056.JPG" label="photo 56" />, <ThumbnailImg src="/images/roof-photos/general/57-071-IMG_4179.JPG" label="photo 57" />
</p></li>
<li>It was noticed that some pipes come through the roof where the
corrugated sheets lap. If water dams in these situations there is the potential for water ingress.</li>
<li><p>
The larger circular vents are also flashed to the roof with Dektite
type flashings. On the majority of roofs, these flashings have also been overlaid with sealants with a substantial quantity being used to fill the corrugations on the high side in an effort to flow water around the vents.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/58-040-IMG_3994.JPG" label="photo 58" />, <ThumbnailImg src="/images/roof-photos/general/59-048-IMG_4032.JPG" label="photo 59" />, <ThumbnailImg src="/images/roof-photos/general/60-044-IMG_4014.JPG" label="photo 60" />
</p>

<p>
There are instances where the top edge of the sealant is loosing adhesion from the rubber boot type flashing.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/61-108-IMG_4405.JPG" label="photo 61" />
</p>

<p>
On some roofs the vents have been reinstalled on watershed or back tray flashings.
</p></li>

<li><p>
On a number of Units, black circular vents with their own molded
corrugated flashing have been installed. A high percentage of heads of the fixing screws securing these vents are rusting.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/62-035-IMG_3984.JPG" label="photo 62" />, <ThumbnailImg src="/images/roof-photos/general/63-067-IMG_4146.JPG" label="photo 63" />, <ThumbnailImg src="/images/roof-photos/general/64-068-IMG_4147.JPG" label="photo 64" />
</p></li>
<li><p>
Sky dishes appear to be securely installed on the roofs with the
fixings sealed where they pass through the crests of the corrugations.
</p>

<p>
In a number of instances the main short, wider bar supporting the dishes tends to accumulate debris on the underside, effectively obstructing water flow.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/65-011-IMG_3983.JPG" label="photo 65" />, <ThumbnailImg src="/images/roof-photos/general/66-010-IMG_4133.JPG" label="photo 66" />, <ThumbnailImg src="/images/roof-photos/general/67-100-IMG_4300.JPG" label="photo 67" />, <ThumbnailImg src="/images/roof-photos/general/68-060-IMG_4099.JPG" label="photo 68" />, <ThumbnailImg src="/images/roof-photos/general/69-105-IMG_4390.JPG" label="photo 69" />
</p></li>

<li><p>
The leads from the sky dish on Unit 10 rely on sealant for weather
security where they pass through a Dektite type flashing.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/70-056-IMG_4066.JPG" label="photo 70" />, <ThumbnailImg src="/images/roof-photos/general/71-055-IMG_4063.JPG" label="photo 71" />
</p></li>
<li><p>
There is an accumulation of dirt/leaf matter under the shelter of the
solar panel on Unit 29.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/73-096-IMG_4269.JPG" label="photo 73" />
</p></li>
</ul>
</div>
</div>
<div id="outline-container-orga8576b5" class="outline-6">
<h6 id="h-wsu4j02008i0">Gutters</h6>
<div class="outline-text-6" id="text-h-wsu4j02008i0">
<ul class="org-ul">
<li>The external gutters could not all be closely viewed. Some are open
and others fitted with leaf protection.</li>
<li>Those sited appear to still be in sound condition.</li>
<li><p>
Some are clean and others holding leaf matter.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/74-013-IMG_3992.JPG" label="photo 74" />, <ThumbnailImg src="/images/roof-photos/general/75-064-IMG_4126.JPG" label="photo 75" />, <ThumbnailImg src="/images/roof-photos/general/76-13-IMG_4040.JPG" label="photo 76" />, <ThumbnailImg src="/images/roof-photos/general/77-102-IMG_4351.JPG" label="photo 77" />, <ThumbnailImg src="/images/roof-photos/general/78-019-IMG_4028.JPG" label="photo 78" />
</p></li>

<li><p>
On Unit 2 in Block 1 it was noticed that wire netting supporting the
roofing underlay which has not been trimmed back overhangs into the gutter and is rusting. What appears to be a patch of rust in the gutter could be staining from the wire netting.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/79-14-IMG_4006.JPG" label="photo 79" />
</p></li>
</ul>
</div>
</div>
</div>
<div id="outline-container-orgc177ee3" class="outline-5">
<h5 id="h-8bx4j02008i0">Conclusions &amp; Recommendations</h5>
<div class="outline-text-5" id="text-h-8bx4j02008i0">
</div>
<div id="outline-container-org678de37" class="outline-6">
<h6 id="h-jxz4j02008i0">Roofs</h6>
<div class="outline-text-6" id="text-h-jxz4j02008i0">
<ul class="org-ul">
<li>Overall the coloursteel roofing sheets appear to be in sound
condition in keeping with their estimated age of 15 years.</li>
<li><p>
The roof surfaces are relatively clean but would benefit from
cleaning.
</p>

<p>
At this stage the lichen growths are relatively small and sparsely spread over the roofs.
</p>

<p>
While a film of dirt/black algae does not appear to damage paintwork, the roots of lichen when established remove the paint back to the zincalume base coating.
</p></li>

<li>The paint is approaching an age where it would benefit from recoating
while the surface is still sound.</li>
<li>Recoating with appropriate primers would also help protect the heads
of fixings where corrosion is in its early stages and not yet showing red rust.</li>
<li>Overall the roof there is a relatively small percentage of roof
fixings which have been over tensioned, slightly indenting the crest of the corrugations. However, the neoprene washers still appear to be sealing and the indentations too shallow to hold water.</li>
<li><p>
The roof fixings need checking and any which are rusting or with
damaged neoprene washers replaced.
</p>

<p>
This also applies to any fixings which are angled or with washers not seating securely.
</p></li>

<li>Sealant placed over the heads of fixings would appear to have been as
a precaution incase any of the above faults were allowing water ingress.</li>
<li><p>
Sealant cannot be considered a reliable or longer term method of
sealing fixings. They should be replaced with new fixings. If there is any indenting or damage around the screw holes the new fixings should also include a profiled metal washer with an underlying neoprene washer.
</p>

<p>
The security of those fixings overlaid with sealant should be checked and the fixings replaced if/as necessary.
</p></li>

<li><p>
The fact that the troughs of the roofing sheets have not been turned
down along the gutter lines does not appear to be affecting the performance of the roofs.
</p>

<p>
(As it is more difficult to effectively turn down the ends of corrugated profiles the New Zealand Metal Roof and Wall Cladding Code of Practices Version 2.2 (CoP) recommends that corrugated profiles should not be used on roof pitches under 8 degrees.)
</p></li>

<li>Monitor and maintain the security of the laps in the short insert
sheets for corrosion as this is an area prone to premature corrosion. Remedy situations where side laps are not correctly installed. Repair/replace as necessary.</li>

<li>The exposed underside of the roofing where it forms eaves appears to
be in sound condition, but as this is an unwashed area prone to premature corrosion it needs to be kept clean. As a guide, manufacturers recommend a minimum of annual cleaning, depending upon locality.</li>
</ul>
</div>
</div>
<div id="outline-container-org5083c3e" class="outline-6">
<h6 id="h-mq35j02008i0">Flashings</h6>
<div class="outline-text-6" id="text-h-mq35j02008i0">
<ul class="org-ul">
<li>The Tek type fixings securing the flashings need checking and any
rusting fixings, fixings incorrectly angled or not seating securely, replaced.</li>
<li>As for the roof fixings, those overlaid with sealant need checking
and replacing as necessary, as sealant is not a reliable longer term remedy for incorrectly installed fixings. On flat surfaces such as flashings, if there is any enlarging of screw holes or indentation around them, new fixings should include an embossed washer.</li>
<li>The security of riveted laps should also be checked and the laps
resealed and fastened as necessary.</li>
<li>Sealant applied over laps would appear to have been as a
precautionary measure incase they were leaking, instead of the actual cause of any suspected water ingress being identified.</li>
<li>The roof would benefit from the soft edges on the flashings being
lifted and accumulated dirt/debris removed, as they increase the risk of corrosion where the roofing sits under the flashings. At the same time the condition of the sheets can be checked and if there is any corrosion it could be treated with an appropriate system.</li>
<li>The top ends of the relatively few short side barge flashings where
roofing planes are of different lengths have not been detailed in accordance with good industry practice or the recommendations of the CoP. The condition of the sealant formed stop ends needs to be monitored as part of a maintenance programme, as the present set up is not easily remedied unless the flashings reinstalled or watershed flashings are extended up the full length of the roof plane.</li>
</ul>
</div>
</div>
<div id="outline-container-org5eb3a4e" class="outline-6">
<h6 id="h-cp65j02008i0">Roof Penetrations</h6>
<div class="outline-text-6" id="text-h-cp65j02008i0">
<ul class="org-ul">
<li><p>
The Dektite type flashings to the smaller vent pipes need monitoring
as part of maintenance. Overlaying the perimeters with sealant appears to have been a precautionary measure incase they were allowing water ingress.
</p>

<p>
This is not a reliable, satisfactory remedial option. It is the sealant under the perimeter of the flashings which ensures their security.
</p></li>

<li><p>
This also applies to the larger circular vents.
</p>

<p>
The forming of a type of back tray by filling the corrugations with sealant is also not an acceptable solution for shedding water around the vents.
</p>

<p>
For longer term security they should be reinstalled on watershed or back tray flashings as already undertaken on several Units.
</p></li>

<li>Watershed flashings also remove the risk of corrosion forming when
water becomes trapped under sealants.</li>
<li><p>
The rusting Tek type screws securing the molded corrugated flashing
to the low profile circular vents need replacing.
</p>

<p>
The security of the sealed laps needs monitoring as part of maintenance.
</p></li>

<li>The roofing under the flat bar supporting the sky dishes needs to be
cleaned as part of maintenance, as on the roofs where the roof planes are subject to leaf matter they are blocking and affecting water flow down the roofs. In more extreme weather this has the potential to allow water ingress if the blockage should fall beside a lap.</li>
</ul>
</div>
</div>
<div id="outline-container-orgafd53e1" class="outline-6">
<h6 id="h-ro95j02008i0">Gutters</h6>
<div class="outline-text-6" id="text-h-ro95j02008i0">
<ul class="org-ul">
<li>Overall they appear sound and need to continue to be kept clean and
their condition monitored as part of maintenance.</li>
<li>Debris from leaf/twig fall can block outlets causing them to overflow
during heavy downpours.</li>
</ul>
</div>
</div>
</div>
<div id="outline-container-org8782c68" class="outline-5">
<h5 id="h-25c5j02008i0"><b>Summary of Recommendations</b></h5>
<div class="outline-text-5" id="text-h-25c5j02008i0">
</div>
<div id="outline-container-orge332941" class="outline-6">
<h6 id="h-qfe5j02008i0">Roofs</h6>
<div class="outline-text-6" id="text-h-qfe5j02008i0">
<ul class="org-ul">
<li>Clean all roof surfaces.</li>
<li>Check condition and security of all roof fixings. Replace as
follows:

<ul class="org-ul">
<li>Rusting fixings</li>
<li>Angled or protruding fixings</li>
<li>Sealant covered fixings with any suspected defects</li>
<li>Any fixings that may warrant replacing where corrugations have been
dished from over tensioning</li>
<li>Include the addition of profiled metal washers where necessary</li>
</ul></li>

<li><p>
Check and maintain the security of the horizontal laps where there
are sheet inserts. Treat any rust as necessary.
</p>

<p>
Remedy situations where there are incorrectly fitted side laps.
</p></li>

<li>The inserts on Unit 38 in particular appear to be used sheets with
poorly fitting laps, patched holes and an under acting as an overlap down one side. Repair/replace as necessary.</li>
<li>After maintenance has been completed, recoat the roofs within the
next 2-3 years while the surface coating is still in sound condition.</li>
</ul>
</div>
</div>
<div id="outline-container-org35bce43" class="outline-6">
<h6 id="h-cih5j02008i0">Flashings</h6>
<div class="outline-text-6" id="text-h-cih5j02008i0">
<ul class="org-ul">
<li>Replace all rusting, angled or Tek type screw fixingss not seating
correctly.</li>
<li>Include the addition of embossed washers to facilitate a secure seal
as necessary.</li>
<li>Check all flashing laps and rivets and reseal and fasten as
necessary.</li>
<li>Clean debris from the underside of soft edge barge flashings. Check
for any signs of corrosion and treat if/as necessary.</li>
<li>Check that there is no debris accumulating under the short open ended
side barge flashings.</li>
<li>Check the condition of roofing where there are side barges with
sealant stop ends and troughs filled with sealant and maintain as necessary. Otherwise re flash the top end as per the recommendations of the CoP or RANZ “How To On Site Guide” guide.</li>
</ul>
</div>
</div>
<div id="outline-container-org9b737fa" class="outline-6">
<h6 id="h-mfk5j02008i0">Roof penetrations</h6>
<div class="outline-text-6" id="text-h-mfk5j02008i0">
<ul class="org-ul">
<li>Check and maintain the Dektite type flashings to any of the smaller
pipes where the surrounding roofing has not been excessively overlaid with sealants.</li>
<li>For all other pipes including the larger circular vents not already
fitted on watershed flashings, re flash to new watershed flashings. This would also remove the risk of leaks in any situations where pipes have been fitted by laps in the roofing sheets.</li>
<li>Replace rusting screws to the black circular vents with moulded
side/apron flashings. Check and maintain horizontal laps.</li>
<li>Clean and keep clear the roofing under sky dish supports.</li>
</ul>
</div>
</div>
<div id="outline-container-orgd8ca883" class="outline-6">
<h6 id="h-tsm5j02008i0">Gutters</h6>
<div class="outline-text-6" id="text-h-tsm5j02008i0">
<ul class="org-ul">
<li>Keep gutters free of debris. The frequency of cleaning will vary in
accordance with proximity of trees, as some Blocks appear more prone to collecting debris than others.</li>
<li>Check for any signs of internal rust when cleaning and treat if/as
necessary.</li>
<li>Keep underside clean as part of maintenance.</li>
</ul>
</div>
</div>
<div id="outline-container-org4e6bab0" class="outline-6">
<h6 id="h-n1p5j02008i0">Maintenance plan</h6>
<div class="outline-text-6" id="text-h-n1p5j02008i0">
<p>
Most of the items listed above are of a nature which should be attended to in the course of a maintenance programme. If not already in place, an annual maintenance programme is recommended which would include: -
</p>

<ul class="org-ul">
<li>Cleaning and checking all gutters. The frequency will vary according
to the rate of accumulation of dirt/debris.</li>
<li>Cleaning lichen, moss and algae off the roof surfaces. The frequency
with which the surfaces of roofs should be cleaned varies according to the proximity to trees and rate of accumulation of dirt/lichen. A guideline would be every 3-5 years in average conditions.</li>
<li>Checking the roofing and flashings.</li>
<li><p>
Washing any naturally unwashed zincalume roofing as per the
manufacturer's recommendations. A minimum guide would be annually.
</p>

<p>
The only areas coming into this category on these roofs would appear to be the underside of the roofing where it forms eaves, underside of the coloursteel gutters and roofing where it overhangs into gutters. The latter is usually a neglected area owing to the difficulty of access and risk of water penetration into roof cavities.
</p></li>
</ul>

<p>
<b>NB. *Leaks reported by owners are specifically addressed under</b> Individual Units.*
</p>
</div>
</div>
</div>
</div>
<div id="outline-container-org7f25cd5" class="outline-4">
<h4 id="h-pfr5j02008i0">Recreational Building</h4>
<div class="outline-text-4" id="text-h-pfr5j02008i0">
</div>
<div id="outline-container-org22c7a3b" class="outline-5">
<h5 id="h-cht5j02008i0">Findings</h5>
<div class="outline-text-5" id="text-h-cht5j02008i0">
<ul class="org-ul">
<li>The Recreational Building has a gabled roof of approximately 15
degrees with painted trapezoidal profile roofing fixed with Springhead type nails.</li>
<li>The paint surface is weathering and starting to expose the original
red roof colour.</li>
<li>The surface is dirty, with a substantial covering of grime and lichen
growths.</li>
<li><p>
The roofing material itself still appears to be sound with no obvious
signs of surface corrosion.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/80-1-DSCF8723.JPG" label="photo 80" />, <ThumbnailImg src="/images/roof-photos/general/81-2-DSCF8725.JPG" label="photo 81" />, <ThumbnailImg src="/images/roof-photos/general/82-3-DSCF8726.JPG" label="photo 82" />
</p></li>

<li><p>
There has been some creasing of the roofing profiles from foot
traffic.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/83-5-DSCF8731.JPG" label="photo 83" />
</p></li>
<li><p>
There is possibly some light surface corrosion starting under side
laps and where the roofing sits under the ridge flashing. In the limited spots viewable, it was difficult to distinguish between the original red paint and possible light surface rust.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/84-6-DSCF8734.JPG" label="photo 84" />, <ThumbnailImg src="/images/roof-photos/general/85-7-DSCF8736.JPG" label="photo 85" />
</p></li>
<li>There were no obvious signs of underside corrosion showing on the
surface or on the underside of the roofing along the gutter lines in the spots sampled.</li>
<li>The Springhead type nails overall still appear to be secure and in
sound condition.</li>
<li><p>
The ridge flashing appear to be in sound condition. One side of a lap
is lifting where the nail has worked loose.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/83-5-DSCF8731.JPG" label="photo 83" />
</p></li>
<li><p>
The fascia/gutter and barge flashings appear to be in sound condition
as viewed from the roof. A film of dirt/debris in the internal gutter inserts made it difficult to view their true condition.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/86-9-DSCF8737.JPG" label="photo 86" />, <ThumbnailImg src="/images/roof-photos/general/87-8-DSCF8738.JPG" label="photo 87" />)
</p></li>
<li><p>
There is a tree overhanging part of the southern facing roof plane,
which is dropping leaf matter on the roof.
</p>

<p>
<ThumbnailImg src="/images/roof-photos/general/81-2-DSCF8725.JPG" label="photo 81" />
</p></li>
</ul>
</div>
</div>
<div id="outline-container-orgb6da8c1" class="outline-5">
<h5 id="h-stw5j02008i0">Conclusions &amp; Recommendations</h5>
<div class="outline-text-5" id="text-h-stw5j02008i0">
<ul class="org-ul">
<li>While overall the roof still appears to be in sound condition, it
needs thorough cleaning and recoating (after remedial work) to maintain a visual appearance in keeping with the Units.</li>
<li>The roof needs checking for any loose fixings, which should be
replaced with Tek type screws.</li>
<li>Any creased roofing profiles other than if only slight, should be
over flashed with a fully sealed matching profile cover flashing, as per the recommendations of the CoP.</li>
<li>As for the roof, the security of the fixings in the ridge flashing
needs checking and any found loose replaced with Tek type screws.</li>
<li>As noted for the Unit roofs, this roof would also benefit from the
soft edges on the ridge flashing being lifted and accumulated dirt/debris removed, as it increases the risk of corrosion where the roofing sits under the flashing. At the same time the condition of the sheets can be checked and if there is any corrosion it could be treated with an appropriate system.</li>
<li>The gutter fascia system appears sound. The bottom edge of this type
of fascia where it folds to support the soffit lining is an unwashed area prone to premature corrosion unless cleaned as part of a maintenance programme.</li>
<li>Based on its condition and type of roof fixings and soft edge
ridging, the age of the roofing would appear to be approximately 16+ years.</li>
</ul>
</div>
</div>
</div>
<div id="outline-container-org79f4317" class="outline-4">
<h4 id="h-8hz5j02008i0">Wall cladding all Units</h4>
<div class="outline-text-4" id="text-h-8hz5j02008i0">
<ul class="org-ul">
<li>It was noticed when first viewing all Units from ground level that
the coloursteel wall cladding and flashings appears to be in overall clean, sound condition.</li>
<li><p>
This also applies to the sheltered and unwashed areas such as in
porches or cladding sheltered by eaves, areas prone to premature corrosion unless kept clean.
</p>

<p>
Manufacturers recommend a minimum of annual cleaning for such areas.
</p></li>

<li>The present cleaning programme appears to be keeping the coloursteel
in good condition.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/general/88-4-IMG_5336.JPG" label="photo 88" />, <ThumbnailImg src="/images/roof-photos/general/89-1-IMG_5344.JPG" label="photo 89" />, <ThumbnailImg src="/images/roof-photos/general/90-2-IMG_5348.JPG" label="photo 90" />, <ThumbnailImg src="/images/roof-photos/general/91-3-IMG_5350.JPG" label="photo 91" />
</p>
</div>
</div>
</div>
<div id="outline-container-org7c304d4" class="outline-3">
<h3 id="h-8r16j02008i0">Individual Units</h3>
<div class="outline-text-3" id="text-h-8r16j02008i0">
<ul class="org-ul">
<li>As noted in the Report, there are only relatively small/minor
differences in the condition of the individual roofs or pairs of roofs.</li>
<li>These in turn largely relate to the quality or extent of repairs and
maintenance which has been carried out to date.</li>
<li>There are also only small differences between the overall condition
of roofs in the separate Blocks or groupings, which mainly relate to the effect of debris from adjacent trees or trees in relatively close proximity.</li>
<li>To give owners of Units a sense of the appearance of their main roofs
in relation to the generic condition of all roofs, as noted in the Report, a random selection of photographs for each unit or pairs of units is included.</li>
<li>A few brief comments are included where considered helpful,
particularly in relation to leaks reported by owners.</li>
</ul>
</div>
<div id="outline-container-org644684c" class="outline-4">
<h4 id="h-fk46j02008i0">Block 1 (Units 1-10)</h4>
<div class="outline-text-4" id="text-h-fk46j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/001-1-DSCF8743.JPG" label="photo 1" />, <ThumbnailImg src="/images/roof-photos/individual/002-1-DSCF8748.JPG" label="photo 2" />, <ThumbnailImg src="/images/roof-photos/individual/003-2-IMG_4368.JPG" label="photo 3" />
Typical generic Units.
</p>
</div>
<div id="outline-container-org0e098cf" class="outline-5">
<h5 id="h-xa76j02008i0">Unit 1</h5>
<div class="outline-text-5" id="text-h-xa76j02008i0">
<ul class="org-ul">
<li>Overflowing gutter to rear lean to roof was not accessed. Taking into
account the location and vicinity of trees the most likely cause is debris blocking the outlet. Needs checking and clearing as necessary.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/004-01-IMG_3980.JPG" label="photo 4" />, <ThumbnailImg src="/images/roof-photos/individual/005-02-IMG_3981.JPG" label="photo 5" />, <ThumbnailImg src="/images/roof-photos/individual/006-03-IMG_3984.JPG" label="photo 6" />
</p>
</div>
</div>
<div id="outline-container-orge7f9801" class="outline-5">
<h5 id="h-0t96j02008i0">Units 2, 3</h5>
<div class="outline-text-5" id="text-h-0t96j02008i0">
<ul class="org-ul">
<li>A discussed with owners onsite, the suspected roof leak allowing
water into the garage is not roof related, but from water running down the wall hitting the block work nib and running into the garage.</li>
<li>Unit 2 was the only one spotted where the wire netting underlay
overhangs into the gutter.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/007-1-IMG_3988.JPG" label="photo 7" />, <ThumbnailImg src="/images/roof-photos/individual/008-04-IMG_3991.JPG" label="photo 8" />, <ThumbnailImg src="/images/roof-photos/individual/009-05-IMG_3993.JPG" label="photo 9" />, <ThumbnailImg src="/images/roof-photos/individual/010-07-IMG_3992.JPG" label="photo 10" />, <ThumbnailImg src="/images/roof-photos/individual/011-08-IMG_3994.JPG" label="photo 11" />, <ThumbnailImg src="/images/roof-photos/individual/012-09-IMG_3996.JPG" label="photo 12" />, <ThumbnailImg src="/images/roof-photos/individual/013-10-IMG_4006.JPG" label="photo 13" />, <ThumbnailImg src="/images/roof-photos/individual/014-3-IMG_3998.JPG" label="photo 14" />, <ThumbnailImg src="/images/roof-photos/individual/015-4-IMG_4002.JPG" label="photo 15" />, <ThumbnailImg src="/images/roof-photos/individual/016-5-IMG_4003.JPG" label="photo 16" />, <ThumbnailImg src="/images/roof-photos/individual/017-06-IMG_4022.JPG" label="photo 17" />
</p>
</div>
</div>
<div id="outline-container-orge79f9a5" class="outline-5">
<h5 id="h-0zb6j02008i0">Units 4, 5</h5>
<div class="outline-text-5" id="text-h-0zb6j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/018-11-IMG_4007.JPG" label="photo 18" />, <ThumbnailImg src="/images/roof-photos/individual/019-02-IMG_4009.JPG" label="photo 19" />, <ThumbnailImg src="/images/roof-photos/individual/020-03-IMG_4010.JPG" label="photo 20" />, <ThumbnailImg src="/images/roof-photos/individual/021-04-IMG_4014.JPG" label="photo 21" />, <ThumbnailImg src="/images/roof-photos/individual/022-05-IMG_4015.JPG" label="photo 22" />, <ThumbnailImg src="/images/roof-photos/individual/023-07-IMG_4020.JPG" label="photo 23" />, <ThumbnailImg src="/images/roof-photos/individual/024-12-IMG_4008.JPG" label="photo 24" />, <ThumbnailImg src="/images/roof-photos/individual/025-14-IMG_4024.JPG" label="photo 25" />, <ThumbnailImg src="/images/roof-photos/individual/026-13-IMG_4012.JPG" label="photo 26" />, <ThumbnailImg src="/images/roof-photos/individual/027-09-IMG_4029.JPG" label="photo 27" />, <ThumbnailImg src="/images/roof-photos/individual/028-16-IMG_4028.JPG" label="photo 28" />, <ThumbnailImg src="/images/roof-photos/individual/029-15-IMG_4025.JPG" label="photo 29" />
</p>
</div>
</div>
<div id="outline-container-org385cb9b" class="outline-5">
<h5 id="h-evd6j02008i0">Units 6, 7</h5>
<div class="outline-text-5" id="text-h-evd6j02008i0">
<ul class="org-ul">
<li>The leak around the bathroom vent cowl reported in Unit 6 would
appear almost certainly to be resulting from the poorly sealed Dektite type flashing around the cowl. It requires re flashing to a watershed flashing as on Unit 7.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/030-17-IMG_4030.JPG" label="photo 30" />, <ThumbnailImg src="/images/roof-photos/individual/031-18-IMG_4031.JPG" label="photo 31" />, <ThumbnailImg src="/images/roof-photos/individual/032-11-IMG_4032.JPG" label="photo 32" />, <ThumbnailImg src="/images/roof-photos/individual/033-12-IMG_4033.JPG" label="photo 33" />, <ThumbnailImg src="/images/roof-photos/individual/034-19-IMG_4035.JPG" label="photo 34" />, <ThumbnailImg src="/images/roof-photos/individual/035-01-IMG_4037.JPG" label="photo 35" />, <ThumbnailImg src="/images/roof-photos/individual/036-21-IMG_4040.JPG" label="photo 36" />, <ThumbnailImg src="/images/roof-photos/individual/037-20-IMG_4038.JPG" label="photo 37" />
</p>
</div>
</div>
<div id="outline-container-org0a87c69" class="outline-5">
<h5 id="h-e3h6j02008i0">Unit 8</h5>
<div class="outline-text-5" id="text-h-e3h6j02008i0">
<ul class="org-ul">
<li>No obvious cause of the leak in upstairs bedroom light socket in May
2018 was sighted. Checking the security of fixings and flashings during a repairs/maintenance programme should remedy any potential weakness which may have caused this leak.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/038-22-IMG_4041.JPG" label="photo 38" />, <ThumbnailImg src="/images/roof-photos/individual/039-02-IMG_4042.JPG" label="photo 39" />, <ThumbnailImg src="/images/roof-photos/individual/040-03-IMG_4046.JPG" label="photo 40" />, <ThumbnailImg src="/images/roof-photos/individual/041-23-IMG_4045.JPG" label="photo 41" />, <ThumbnailImg src="/images/roof-photos/individual/042-04-IMG_4051.JPG" label="photo 42" />
</p>
</div>
</div>
<div id="outline-container-org1014e4e" class="outline-5">
<h5 id="h-w7k6j02008i0">Units 9, 10</h5>
<div class="outline-text-5" id="text-h-w7k6j02008i0">
<ul class="org-ul">
<li>Unit 10 is the only unit where the leads from the TV pass through a
Dektite type rubber boot flashing and rely on sealant where they pass through the flashing. The seal needs to be monitored as part of maintenance.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/043-24-IMG_4052.JPG" label="photo 43" />, <ThumbnailImg src="/images/roof-photos/individual/044-05-IMG_4053.JPG" label="photo 44" />, <ThumbnailImg src="/images/roof-photos/individual/045-06-IMG_4054.JPG" label="photo 45" />, <ThumbnailImg src="/images/roof-photos/individual/046-07-IMG_4056.JPG" label="photo 46" />, <ThumbnailImg src="/images/roof-photos/individual/047-25-IMG_4055.JPG" label="photo 47" />, <ThumbnailImg src="/images/roof-photos/individual/048-08-IMG_4058.JPG" label="photo 48" />, <ThumbnailImg src="/images/roof-photos/individual/049-26-IMG_4059.JPG" label="photo 49" />, <ThumbnailImg src="/images/roof-photos/individual/050-09-IMG_4060.JPG" label="photo 50" />, <ThumbnailImg src="/images/roof-photos/individual/051-10-IMG_4061.JPG" label="photo 51" />, <ThumbnailImg src="/images/roof-photos/individual/052-11-IMG_4064.JPG" label="photo 52" />, <ThumbnailImg src="/images/roof-photos/individual/053-27-IMG_4066.JPG" label="photo 53" />, <ThumbnailImg src="/images/roof-photos/individual/054-28-IMG_4063.JPG" label="photo 54" />
</p>
</div>
</div>
</div>
<div id="outline-container-org31bfd54" class="outline-4">
<h4 id="h-djm6j02008i0">Block 2 (Units 11-18)</h4>
<div class="outline-text-4" id="text-h-djm6j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/055-003-DSCF8741.JPG" label="photo 55" />
</p>

<p>
Typical generic units, only these roofs are more prone to the accumulation of debris from adjacent trees.
</p>

<p>
These units are also prone to leaf matter accumulating under Sky dish supports and blocking the corrugations.
</p>
</div>
<div id="outline-container-orgc688608" class="outline-5">
<h5 id="h-8eo6j02008i0">Units 11, 12</h5>
<div class="outline-text-5" id="text-h-8eo6j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/056-001-IMG_4088.JPG" label="photo 56" />, <ThumbnailImg src="/images/roof-photos/individual/057-002-IMG_4089.JPG" label="photo 57" />, <ThumbnailImg src="/images/roof-photos/individual/058-003-IMG_4090.JPG" label="photo 58" />, <ThumbnailImg src="/images/roof-photos/individual/059-004-IMG_4093.JPG" label="photo 59" />, <ThumbnailImg src="/images/roof-photos/individual/060-005-IMG_4098.JPG" label="photo 60" />, <ThumbnailImg src="/images/roof-photos/individual/061-006-IMG_4100.JPG" label="photo 61" />
</p>
</div>
</div>
<div id="outline-container-org6d84504" class="outline-5">
<h5 id="h-5aq6j02008i0">Units 13, 14</h5>
<div class="outline-text-5" id="text-h-5aq6j02008i0">
<ul class="org-ul">
<li>Unit 14 is the only unit where the main circular vent is flashed to
both the roof and barge flashing. This creates a potential at risk area. The pipe should be re flashed to a wider section of barge or watershed flashing.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/062-007-IMG_4106.JPG" label="photo 62" />, <ThumbnailImg src="/images/roof-photos/individual/063-008-IMG_4107.JPG" label="photo 63" />, <ThumbnailImg src="/images/roof-photos/individual/064-009-IMG_4108.JPG" label="photo 64" />, <ThumbnailImg src="/images/roof-photos/individual/065-010-IMG_4111.JPG" label="photo 65" />, <ThumbnailImg src="/images/roof-photos/individual/066-011-IMG_4112.JPG" label="photo 66" />, <ThumbnailImg src="/images/roof-photos/individual/067-012-IMG_4113.JPG" label="photo 67" />, <ThumbnailImg src="/images/roof-photos/individual/068-013-IMG_4117.JPG" label="photo 68" />
</p>
</div>
</div>
<div id="outline-container-org5925884" class="outline-5">
<h5 id="h-0as6j02008i0">Units 15, 16</h5>
<div class="outline-text-5" id="text-h-0as6j02008i0">
<ul class="org-ul">
<li>On Unit 15 there is a screw fastening through the side of the apron
flashing to Unit 14 <ThumbnailImg src="/images/roof-photos/individual/071-016-IMG_4125.JPG" label="photo 71" />. It appears to be over sealed but needs to be checked, as it is low down in the roofing pan.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/069-014-IMG_4121.JPG" label="photo 69" />, <ThumbnailImg src="/images/roof-photos/individual/070-015-IMG_4123.JPG" label="photo 70" />, <ThumbnailImg src="/images/roof-photos/individual/071-016-IMG_4125.JPG" label="photo 71" />, <ThumbnailImg src="/images/roof-photos/individual/072-017-IMG_4126.JPG" label="photo 72" />, <ThumbnailImg src="/images/roof-photos/individual/073-018-IMG_4129.JPG" label="photo 73" />, <ThumbnailImg src="/images/roof-photos/individual/074-019-IMG_4130.JPG" label="photo 74" />, <ThumbnailImg src="/images/roof-photos/individual/075-020-IMG_4132.JPG" label="photo 75" />
</p>
</div>
</div>
<div id="outline-container-org11011b2" class="outline-5">
<h5 id="h-bdu6j02008i0">Units 17, 18</h5>
<div class="outline-text-5" id="text-h-bdu6j02008i0">
<ul class="org-ul">
<li>Unit 18 reported that there had been some water damage to the
upstairs bathroom ceiling but there hasn't been a leak as far as they know in the past few years.</li>
<li>The most likely cause of any leak in the bathroom would be from the
manner of installation of the circular vent as its associated molded corrugated flashing. The perimeter appears to have been over sealed at some stage. As noted in the generic report, it is one of the types of vent where the fixings are rusting and need replacing. At the same time the seal of the corrugated flashing to the roof needs checking.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/076-021-IMG_4139.JPG" label="photo 76" />, <ThumbnailImg src="/images/roof-photos/individual/077-022-IMG_4141.JPG" label="photo 77" />, <ThumbnailImg src="/images/roof-photos/individual/078-023-IMG_4145.JPG" label="photo 78" />, <ThumbnailImg src="/images/roof-photos/individual/079-024-IMG_4146.JPG" label="photo 79" />, <ThumbnailImg src="/images/roof-photos/individual/080-025-IMG_4150.JPG" label="photo 80" />, <ThumbnailImg src="/images/roof-photos/individual/081-026-IMG_4151.JPG" label="photo 81" />, <ThumbnailImg src="/images/roof-photos/individual/082-027-IMG_4152.JPG" label="photo 82" />, <ThumbnailImg src="/images/roof-photos/individual/083-028-IMG_4153.JPG" label="photo 83" />
</p>
</div>
</div>
</div>
<div id="outline-container-org88b63dd" class="outline-4">
<h4 id="h-ikw6j02008i0">Block 3 (Units 19-26)</h4>
<div class="outline-text-4" id="text-h-ikw6j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/084-004-DSCF8746.JPG" label="photo 84" />, <ThumbnailImg src="/images/roof-photos/individual/085-09-IMG_4248.JPG" label="photo 85" />
</p>

<p>
Typical generic units.
</p>
</div>
<div id="outline-container-org601402c" class="outline-5">
<h5 id="h-nky6j02008i0">Unit 19</h5>
<div class="outline-text-5" id="text-h-nky6j02008i0">
<ul class="org-ul">
<li>The leak in the upstairs bathroom next to the extractor fan which has
been remedied, appears to have had the perimeter of the flashing overlaid with sealant.</li>
<li>As noted in the generic report, it is one of the types of vent where
the fixings are rusting and need replacing. At the same time the seal of the corrugated flashing to the roof needs checking.</li>
<li>This roof also has two green coloursteel inserts. On one the side lap
is an under instead of over lap allowing water to be drawn between the sheets. This creates the conditions for premature corrosion over the longer term and needs remedying.</li>
<li>The security of the horizontal laps also needs monitoring as part of
maintenance.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/086-029-IMG_4159.JPG" label="photo 86" />, <ThumbnailImg src="/images/roof-photos/individual/087-030-IMG_4160.JPG" label="photo 87" />, <ThumbnailImg src="/images/roof-photos/individual/088-031-IMG_4161.JPG" label="photo 88" />, <ThumbnailImg src="/images/roof-photos/individual/089-032-IMG_4162.JPG" label="photo 89" />, <ThumbnailImg src="/images/roof-photos/individual/090-033-IMG_4163.JPG" label="photo 90" />
</p>
</div>
</div>
<div id="outline-container-orga17eb2e" class="outline-5">
<h5 id="h-u917j02008i0">Units 20, 21</h5>
<div class="outline-text-5" id="text-h-u917j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/091-034-IMG_4168.JPG" label="photo 91" />, <ThumbnailImg src="/images/roof-photos/individual/092-035-IMG_4169.JPG" label="photo 92" />, <ThumbnailImg src="/images/roof-photos/individual/093-036-IMG_4176.JPG" label="photo 93" />, <ThumbnailImg src="/images/roof-photos/individual/094-037-IMG_4177.JPG" label="photo 94" />, <ThumbnailImg src="/images/roof-photos/individual/095-038-IMG_4180.JPG" label="photo 95" />, <ThumbnailImg src="/images/roof-photos/individual/096-044-IMG_4181.JPG" label="photo 96" />
</p>
</div>
</div>
<div id="outline-container-orgfb11767" class="outline-5">
<h5 id="h-ur37j02008i0">Units 22, 23</h5>
<div class="outline-text-5" id="text-h-ur37j02008i0">
<ul class="org-ul">
<li>The full length of the side apron flashing on Unit 22 has been sealed
to the roof. This appears to have been done as a possible solution to a suspected leak, without a specific cause being identified. It is not a recommended practice. If there were a problem in this area the flashing should be remedied/replaced as necessary.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/097-039-IMG_4182.JPG" label="photo 97" />, <ThumbnailImg src="/images/roof-photos/individual/098-040-IMG_4183.JPG" label="photo 98" />, <ThumbnailImg src="/images/roof-photos/individual/099-041-IMG_4185.JPG" label="photo 99" />, <ThumbnailImg src="/images/roof-photos/individual/100-042-IMG_4189.JPG" label="photo 100" />, <ThumbnailImg src="/images/roof-photos/individual/101-043-IMG_4191.JPG" label="photo 101" />, <ThumbnailImg src="/images/roof-photos/individual/102-045-IMG_4194.JPG" label="photo 102" />
</p>
</div>
</div>
<div id="outline-container-orgdc71124" class="outline-5">
<h5 id="h-3h67j02008i0">Units 24, 25</h5>
<div class="outline-text-5" id="text-h-3h67j02008i0">
<ul class="org-ul">
<li>The blue coloursteel insert in Unit 25 is rusting on one horizontal
lap and has been installed with one side lap being and under instead of overlap.</li>
<li>The side lap creates the conditions for premature corrosion over the
longer term and needs remedying. The rusting lap also needs treating and coating or the roofing sheets replaced as necessary.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/103-046-IMG_4198.JPG" label="photo 103" />, <ThumbnailImg src="/images/roof-photos/individual/104-047-IMG_4199.JPG" label="photo 104" />, <ThumbnailImg src="/images/roof-photos/individual/105-048-IMG_4205.JPG" label="photo 105" />, <ThumbnailImg src="/images/roof-photos/individual/106-049-IMG_4208.JPG" label="photo 106" />, <ThumbnailImg src="/images/roof-photos/individual/107-050-IMG_4209.JPG" label="photo 107" />
</p>
</div>
</div>
<div id="outline-container-orgd430852" class="outline-5">
<h5 id="h-xz87j02008i0">Unit 26</h5>
<div class="outline-text-5" id="text-h-xz87j02008i0">
<ul class="org-ul">
<li>Reported that a leak in the upstairs bathroom next to the extractor
fan had been fixed and at the same time there had been a leak above the lounge.</li>
<li>The extractor fan has been installed on a charcoal coloursteel sheet
which appears to have been overlaid over the existing roof, with the ends over sealed with a white liquid type membrane. The integrity of the laps and sealants will need to be maintained as part of maintenance.</li>
<li>There has also been a new wide cover plain zincalume apron flashing
fitted against the step up to Units 24/25. The above works would appear to have been undertaken to resolve the leaks.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/108-051-IMG_4215.JPG" label="photo 108" />, <ThumbnailImg src="/images/roof-photos/individual/109-052-IMG_4216.JPG" label="photo 109" />, <ThumbnailImg src="/images/roof-photos/individual/110-053-IMG_4218.JPG" label="photo 110" />, <ThumbnailImg src="/images/roof-photos/individual/111-054-IMG_4224.JPG" label="photo 111" />, <ThumbnailImg src="/images/roof-photos/individual/112-055-IMG_4225.JPG" label="photo 112" />, <ThumbnailImg src="/images/roof-photos/individual/113-056-IMG_4227.JPG" label="photo 113" />
</p>
</div>
</div>
</div>
<div id="outline-container-org0c7f767" class="outline-4">
<h4 id="h-elc7j02008i0">Block 4 (Units 27-29)</h4>
<div class="outline-text-4" id="text-h-elc7j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/114-04-IMG_4366.JPG" label="photo 114" />, <ThumbnailImg src="/images/roof-photos/individual/115-08-IMG_4345.JPG" label="photo 115" />, <ThumbnailImg src="/images/roof-photos/individual/116-9-DSCF8715.JPG" label="photo 116" />*
</p>

<p>
Typical generic units, only more prone to debris from adjacent trees.
</p>

<p>
These units are also prone to leaf matter accumulating under Sky dish supports and blocking the corrugations.
</p>
</div>
<div id="outline-container-org0e7d7ad" class="outline-5">
<h5 id="h-zte7j02008i0">Units 27, 28</h5>
<div class="outline-text-5" id="text-h-zte7j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/117-057-IMG_4249.JPG" label="photo 117" />, <ThumbnailImg src="/images/roof-photos/individual/118-058-IMG_4250.JPG" label="photo 118" />, <ThumbnailImg src="/images/roof-photos/individual/119-059-IMG_4252.JPG" label="photo 119" />, <ThumbnailImg src="/images/roof-photos/individual/120-060-IMG_4256.JPG" label="photo 120" />, <ThumbnailImg src="/images/roof-photos/individual/121-061-IMG_4257.JPG" label="photo 121" />, <ThumbnailImg src="/images/roof-photos/individual/122-062-IMG_4258.JPG" label="photo 122" />, <ThumbnailImg src="/images/roof-photos/individual/123-063-IMG_4260.JPG" label="photo 123" />, <ThumbnailImg src="/images/roof-photos/individual/124-064-IMG_4263.JPG" label="photo 124" />
</p>
</div>
</div>
<div id="outline-container-orgd494436" class="outline-5">
<h5 id="h-pqh7j02008i0">Unit 29</h5>
<div class="outline-text-5" id="text-h-pqh7j02008i0">
<ul class="org-ul">
<li>The roof area under cover of the solar panel needs to be cleaned of
the accumulation of leaf matter/debris, as if left, longer term could cause premature corrosion.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/125-065-IMG_4268.JPG" label="photo 125" />, <ThumbnailImg src="/images/roof-photos/individual/126-066-IMG_4269.JPG" label="photo 126" />, <ThumbnailImg src="/images/roof-photos/individual/127-067-IMG_4275.JPG" label="photo 127" />, <ThumbnailImg src="/images/roof-photos/individual/128-068-IMG_4279.JPG" label="photo 128" />
</p>
</div>
</div>
</div>
<div id="outline-container-org9504738" class="outline-4">
<h4 id="h-37l7j02008i0">Block 5 (Units 30-35)</h4>
<div class="outline-text-4" id="text-h-37l7j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/129-1-IMG_4367.JPG" label="photo 129" />, <ThumbnailImg src="/images/roof-photos/individual/130-7-IMG_4365.JPG" label="photo 130" />
</p>

<p>
Typical generic units, only more subject to accumulation of debris from adjacent trees.
</p>

<p>
These units are also prone to leaf matter accumulating under Sky dish supports and blocking the corrugations.
</p>
</div>
<div id="outline-container-org961a701" class="outline-5">
<h5 id="h-zcn7j02008i0">Units 30 31</h5>
<div class="outline-text-5" id="text-h-zcn7j02008i0">
<ul class="org-ul">
<li>No obvious cause of the watermarks reported on the ceilings in Unit
31 could be seen from on the roof. The marks and their positions would need to be viewed from inside the house, as suggested by the owner, to better understand a potential cause.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/131-069-IMG_4281.JPG" label="photo 131" />, <ThumbnailImg src="/images/roof-photos/individual/132-070-IMG_4284.JPG" label="photo 132" />, <ThumbnailImg src="/images/roof-photos/individual/133-071-IMG_4290.JPG" label="photo 133" />, <ThumbnailImg src="/images/roof-photos/individual/134-072-IMG_4292.JPG" label="photo 134" />, <ThumbnailImg src="/images/roof-photos/individual/135-073-IMG_4294.JPG" label="photo 135" />, <ThumbnailImg src="/images/roof-photos/individual/136-074-IMG_4296.JPG" label="photo 136" />, <ThumbnailImg src="/images/roof-photos/individual/137-075-IMG_4298.JPG" label="photo 137" />
</p>
</div>
</div>
<div id="outline-container-org5ef6d04" class="outline-5">
<h5 id="h-nfp7j02008i0">Units 32, 33</h5>
<div class="outline-text-5" id="text-h-nfp7j02008i0">
<ul class="org-ul">
<li>Unit 33. No obvious cause of the leaks reported just inside the
bathroom window, above the stairs in the middle and a water mark running across the living room could be clearly determined from on the roof.</li>
<li>The most likely causes would appear to be from the manner in which
the vent pipe is flashed and the cladding over the apron flashing to Unit 34.</li>
<li>The integrity of the pipe flashing needs checking and the pipe re
flashed as noted in the generic report.</li>
<li>It appears as if the flat sheet cladding against the step up to Unit
34 has recently been replaced. There is a gap where the sheets meet which needs checking to see whether there is an under flashing.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/138-076-IMG_4303.JPG" label="photo 138" />, <ThumbnailImg src="/images/roof-photos/individual/139-077-IMG_4304.JPG" label="photo 139" />, <ThumbnailImg src="/images/roof-photos/individual/140-078-IMG_4306.JPG" label="photo 140" />, <ThumbnailImg src="/images/roof-photos/individual/141-079-IMG_4307.JPG" label="photo 141" />, <ThumbnailImg src="/images/roof-photos/individual/142-080-IMG_4309.JPG" label="photo 142" />, <ThumbnailImg src="/images/roof-photos/individual/143-081-IMG_4310.JPG" label="photo 143" />, <ThumbnailImg src="/images/roof-photos/individual/144-082-IMG_4311.JPG" label="photo 144" />, <ThumbnailImg src="/images/roof-photos/individual/145-083-IMG_4314.JPG" label="photo 145" />
</p>
</div>
</div>
<div id="outline-container-orgb78e25a" class="outline-5">
<h5 id="h-6or7j02008i0">Units 34, 35</h5>
<div class="outline-text-5" id="text-h-6or7j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/146-084-IMG_4318.JPG" label="photo 146" />, <ThumbnailImg src="/images/roof-photos/individual/147-085-IMG_4319.JPG" label="photo 147" />, <ThumbnailImg src="/images/roof-photos/individual/148-086-IMG_4320.JPG" label="photo 148" />, <ThumbnailImg src="/images/roof-photos/individual/149-087-IMG_4324.JPG" label="photo 149" />, <ThumbnailImg src="/images/roof-photos/individual/150-088-IMG_4328.JPG" label="photo 150" />, <ThumbnailImg src="/images/roof-photos/individual/151-089-IMG_4329.JPG" label="photo 151" />, <ThumbnailImg src="/images/roof-photos/individual/152-090-IMG_4330.JPG" label="photo 152" />, <ThumbnailImg src="/images/roof-photos/individual/153-091-IMG_4336.JPG" label="photo 153" />
</p>
</div>
</div>
</div>
<div id="outline-container-org35d1638" class="outline-4">
<h4 id="h-8ft7j02008i0">Block 6 (Units 36-39)</h4>
<div class="outline-text-4" id="text-h-8ft7j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/154-3-DSCF8745.JPG" label="photo 154" />, <ThumbnailImg src="/images/roof-photos/individual/155-1-IMG_4363.JPG" label="photo 155" />
</p>
</div>

<div id="outline-container-org5c0869c" class="outline-5">
<h5 id="h-wew7j02008i0">Unit 36</h5>
<div class="outline-text-5" id="text-h-wew7j02008i0">
<ul class="org-ul">
<li>A direct cause of water marking on the ceilings of the 2 bedrooms
upstairs closest to 37 was not obvious from on the roof. The only penetrations in this general area are the vent pipe and extractor fan.</li>
<li>The nature and exact position of the marks would need to be inspected
and related to the roof. If no direct cause is obvious the sheets over this area need to be lifted to determine any points of entry.</li>
<li>Overflowing gutters are generally the result of blocked outlets
(unless originally installed with incorrect falls). This needs to be checked as part of maintenance.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/156-092-IMG_4369.JPG" label="photo 156" />, <ThumbnailImg src="/images/roof-photos/individual/157-093-IMG_4370.JPG" label="photo 157" />, <ThumbnailImg src="/images/roof-photos/individual/158-094-IMG_4371.JPG" label="photo 158" />, <ThumbnailImg src="/images/roof-photos/individual/159-095-IMG_4374.JPG" label="photo 159" />, <ThumbnailImg src="/images/roof-photos/individual/160-096-IMG_4377.JPG" label="photo 160" />
</p>
</div>
</div>
<div id="outline-container-org9851dfc" class="outline-5">
<h5 id="h-c5z7j02008i0">Units 37, 38</h5>
<div class="outline-text-5" id="text-h-c5z7j02008i0">
<ul class="org-ul">
<li>The leaks in Unit 38 being experienced in heavy rain are of concern,
but unlikely to be a result of the roofing system not coping, as they would be happening in other similar roofs.</li>
<li>There is a repair in the middle of the roof where the roof has been
patched with corrugated sheeting, which has both poorly fitting horizontal laps and side laps where the under lap is installed as an overlap, allowing water to be drawn between the surfaces. This needs remedying as noted in the generic report.</li>
<li>The seal of the Dektite type flashing to the circular vent is also
suspect and the vent needs re flashing onto a watershed flashing.</li>
</ul>

<p>
<ThumbnailImg src="/images/roof-photos/individual/161-07-IMG_4363.JPG" label="photo 161" />, <ThumbnailImg src="/images/roof-photos/individual/162-098-IMG_4385.JPG" label="photo 162" />, <ThumbnailImg src="/images/roof-photos/individual/163-099-IMG_4386.JPG" label="photo 163" />, <ThumbnailImg src="/images/roof-photos/individual/164-100-IMG_4390.JPG" label="photo 164" />, <ThumbnailImg src="/images/roof-photos/individual/165-101-IMG_4392.JPG" label="photo 165" />, <ThumbnailImg src="/images/roof-photos/individual/166-102-IMG_4393.JPG" label="photo 166" />, <ThumbnailImg src="/images/roof-photos/individual/167-103-IMG_4395.JPG" label="photo 167" />, <ThumbnailImg src="/images/roof-photos/individual/168-112-SAM_0579.JPG" label="photo 168" />, <ThumbnailImg src="/images/roof-photos/individual/169-104-IMG_4396.JPG" label="photo 169" />, <ThumbnailImg src="/images/roof-photos/individual/170-105-IMG_4398.JPG" label="photo 170" />, <ThumbnailImg src="/images/roof-photos/individual/171-107-IMG_4401.JPG" label="photo 171" />, <ThumbnailImg src="/images/roof-photos/individual/172-108-IMG_4402.JPG" label="photo 172" />, <ThumbnailImg src="/images/roof-photos/individual/173-106-IMG_4399.JPG" label="photo 173" />, <ThumbnailImg src="/images/roof-photos/individual/174-109-IMG_4403.JPG" label="photo 174" />, <ThumbnailImg src="/images/roof-photos/individual/175-110-IMG_4405.JPG" label="photo 175" />
</p>
</div>
</div>
<div id="outline-container-org9dc2fda" class="outline-5">
<h5 id="h-9x18j02008i0">Unit 39</h5>
<div class="outline-text-5" id="text-h-9x18j02008i0">
<p>
<ThumbnailImg src="/images/roof-photos/individual/176-8-DSCF8713.JPG" label="photo 176" />, <ThumbnailImg src="/images/roof-photos/individual/177-113-SAM_0584.JPG" label="photo 177" />, <ThumbnailImg src="/images/roof-photos/individual/178-114-SAM_0585.JPG" label="photo 178" />, <ThumbnailImg src="/images/roof-photos/individual/179-116-SAM_0588.JPG" label="photo 179" />, <ThumbnailImg src="/images/roof-photos/individual/180-115-SAM_0591.JPG" label="photo 180" />
</p>
</div>
</div>
</div>
</div>
<div id="outline-container-orgf90042c" class="outline-3">
<h3 id="h-l748j02008i0">Summary (all Roofs)</h3>
<div class="outline-text-3" id="text-h-l748j02008i0">
<ul class="org-ul">
<li>Overall, the roofs are of clean, simple design, which should not be
prone to causing leaks.</li>
<li>Even though the roof pitch appears to be slightly below todays
recommended minimum of 8 degrees for corrugated roofs, it still appears to have the carrying capacity to perform satisfactorily.</li>
<li>The evidence on the roofs would suggest that leaks being experienced
to date are mainly related to incorrectly and/or poorly installed flashings to roof penetrations, along with inaccurately installed and tensioned roof and flashing fixings.</li>
<li>Much of the repair work appears to have been reliant on the excessive
use of sealants to overlay fixings and flashing laps, instead of the actual cause of any water penetration being identified and remedied in accordance with good industry practice.</li>
<li>The incorrect side lapping of several sheet inserts also creates
situations with the potential for premature corrosion. End laps are unavoidable in such situations but need to be carefully monitored and maintained, as any moisture drawn between laps also causes corrosion.</li>
<li>It is always difficult to give an accurate estimate of the potential
life of a metal roof as it can be influenced by a number of different factors such as material type and finish, locality, direction it faces, pitch, quality of maintenance and standard of the original installation.</li>
<li>Historically galvanised roofs in a non-corrosive environment have
performed for 35 to 40 years and sometimes longer with appropriate maintenance.</li>
<li>Roofs installed since 1994, such as on these Units, will almost
certainly be zincalume based coloursteel. This material has only been in wide use on the New Zealand market since 1994, so does not as yet have the proven long term history of galvanised roofing.</li>
<li><p>
The manufacturers expect zincalume, in a non-corrosive atmosphere, to
perform as well as if not better than the galvanised product it replaces. However, this depends upon the product being properly maintained, as zincalume has different weathering characteristics from galvanised steel.
</p>

<p>
Evidence to date has shown that it can corrode prematurely in unwashed areas, positions where moisture is held between surfaces in close contact (such as horizontal laps), and areas of prolonged immersion such as in gutters which are not free draining. Zincalume gutters may, therefore, fail and need repairing/replacing before the roofing.
</p></li>

<li>For the above reasons and taking into account the performance of the
roofs to date, the estimated life span to replacement of the zincalume roofs on these Units is a more conservative 18 years (or possibly a little longer) with an appropriate maintenance programme.</li>
<li><p>
Recoating coloursteel zincalume roofs at around 15-18 years while the
paint is still in good condition will continue to preserve the surface coating.
</p>

<p>
Top coating does not reach the areas which normally first fail from underside corrosion, such as the underside of the roofing along the gutter lines, under laps and flashings.
</p>

<p>
While re coating does help protect the top surface and improve the aesthetics of a roof, it may not necessarily extend its overall life where a roof is within 10-15 years of its practical serviceable life.
</p>

<p>
To gain the maximum potential serviceable life from the roofs, it is important that they are maintained in accordance with manufacturers' recommendations.
</p></li>

<li>When the time comes to reroof, it is recommended that the corrugated
roofs are replaced with a 0.55 gauge trapezoidal profile (style as on the Recreational Centre) coloursteel roof which has a higher water carrying capacity for the pitch and is less prone to damage from foot traffic.</li>
</ul>

<p>
It is recommended that all roofing work is carried out by appropriately trained and experienced contractors who are also members of the Roofing Association of New Zealand.
</p>

<p>
Please do not hesitate to contact me if you have any queries or require further information.
</p>

<p>
Des Cowperthwaite,
</p>

<p>
Director,
</p>

<p>
NZ Roofing Consultants Ltd.
</p>

<p>
<b>Disclaimer:</b> <i>This report has been prepared solely for the benefit of you as my client with respect to the brief given to me, and data or opinions contained in it may not be used in other contexts or for other purposes without my prior review or agreement. New Zealand Roofing Consultants does not accept any responsibility or liability whatsoever for any action taken or outcomes arising in connection with this roofing inspection and report.</i>
</p>
</div>
</div>
</div>

 {/* CONTENT END */}
        </Layout>
)
};
